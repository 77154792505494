/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed, type MaybeRef } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'

export default function useAccountsReceivableNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { navigateTo } = useNavigation()
  const { getEngagementLocalePath } = useEngagementNavigation(organisation, engagement, phase)

  const accountsReceivableIndexLocalePath = computed(() =>
    getEngagementLocalePath('accountsReceivable-Index'),
  )
  const accountsReceivableMasterDataLocalePath = computed(() =>
    getEngagementLocalePath('accountsReceivable-masterData-Debtors'),
  )
  const accountsReceivableOverviewLocalePath = computed(() =>
    getEngagementLocalePath('accountsReceivable-Overview'),
  )
  const accountsReceivableExplorerLocalePath = computed(() =>
    getEngagementLocalePath('accountsReceivable-explorer-AccountsReceivables'),
  )

  const navigateToAccountsReceivableIndexPage = async (): Promise<void> => {
    await navigateTo(accountsReceivableIndexLocalePath.value)
  }

  const navigateToAccountsReceivableOverviewPage = async (): Promise<void> => {
    await navigateTo(accountsReceivableOverviewLocalePath.value)
  }

  return {
    accountsReceivableIndexLocalePath,
    accountsReceivableMasterDataLocalePath,
    accountsReceivableOverviewLocalePath,
    accountsReceivableExplorerLocalePath,
    navigateToAccountsReceivableIndexPage,
    navigateToAccountsReceivableOverviewPage,
  }
}
